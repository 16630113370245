import "./App.css";
import Translate from "./components/Translate";
function App() {
  return (
    <>
      <Translate />
    </>
  );
}

export default App;
